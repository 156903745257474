<template>
  <div id="app" class="container">
    <TheModal />
    <TheHeader />
    <router-view />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheModal from "@/components/TheModal.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheModal,
  },
};
</script>

<style>
html,
body {
  min-height: 100vh;
  background-color: #80808012 !important;
}

#app {
  min-height: 100vh;
  background-color: white;
}
</style>