<template>
  <main>
    <h4 class="text-center">{{ $t("editServer.title") }}</h4>
    <b-form @submit="onSubmit">
      <fieldset class="border p-2">
        <legend class="w-auto">{{ $t("createServer.general.title") }}</legend>

        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('createServer.general.name.label')"
                label-for="name"
              >
                <b-form-input
                  v-model="name"
                  :placeholder="$t('createServer.general.name.placeholder')"
                  required
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.general.motd.label')"
                label-for="motd"
              >
                <b-form-input
                  v-model="motd"
                  :placeholder="$t('createServer.general.motd.placeholder')"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.general.version')"
                label-for="version"
              >
                <b-form-select
                  v-model="version"
                  :options="versions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                :label="$t('createServer.general.maxPlayers')"
                label-for="maxPlayers"
              >
                <b-form-input
                  v-model="maxPlayers"
                  type="number"
                  min="1"
                  max="100"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.general.enableWhitelist.label')"
                label-for="whitelist"
              >
                <b-form-checkbox v-model="whitelist">
                  {{ $t("createServer.general.enableWhitelist.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </fieldset>

      <fieldset class="border p-2">
        <legend class="w-auto">
          {{ $t("createServer.configuration.title") }}
        </legend>

        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('createServer.configuration.gamemode')"
                label-for="gamemode"
              >
                <b-form-select
                  v-model="gamemode"
                  :options="gamemodes"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.configuration.difficulty')"
                label-for="difficulty"
              >
                <b-form-select
                  v-model="difficulty"
                  :options="difficulties"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                :label="$t('createServer.configuration.hardcore.label')"
                label-for="hardcore"
              >
                <b-form-checkbox v-model="hardcore">
                  {{ $t("createServer.configuration.hardcore.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.configuration.fire.label')"
                label-for="fire"
              >
                <b-form-checkbox v-model="fire">
                  {{ $t("createServer.configuration.fire.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.configuration.pvp.label')"
                label-for="pvp"
              >
                <b-form-checkbox v-model="pvp">
                  {{ $t("createServer.configuration.pvp.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </fieldset>

      <fieldset class="border p-2">
        <legend class="w-auto">{{ $t("createServer.spawns.title") }}</legend>

        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('createServer.spawns.monsters.label')"
                label-for="monsters"
              >
                <b-form-checkbox v-model="monsters">
                  {{ $t("createServer.spawns.monsters.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.spawns.animals.label')"
                label-for="animals"
              >
                <b-form-checkbox v-model="animals">
                  {{ $t("createServer.spawns.animals.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                :label="$t('createServer.spawns.npcs.label')"
                label-for="npcs"
              >
                <b-form-checkbox v-model="monsters">
                  {{ $t("createServer.spawns.npcs.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('createServer.spawns.structures.label')"
                label-for="structures"
              >
                <b-form-checkbox v-model="structures">
                  {{ $t("createServer.spawns.structures.checkbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </fieldset>

      <fieldset class="border p-2" v-if="whitelist">
        <legend class="w-auto">{{ $t("createServer.whitelist.title") }}</legend>

        <b-form-group
          :label="$t('createServer.whitelist.label')"
          label-for="whitelistPlayer"
        >
          <b-input-group class="mt-3">
            <b-form-input
              v-model="whitelistPlayer"
              :placeholder="$t('createServer.whitelist.placeholder')"
              trim
              @enter="addWhitelistPlayer"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="addWhitelistPlayer">
                {{ $t("createServer.whitelist.button") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-table-simple hover responsive>
          <b-thead head-variant="light">
            <b-tr>
              <b-th>
                {{ $t("createServer.whitelist.player") }}
              </b-th>
              <b-th>
                {{ $t("createServer.whitelist.delete") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="player in whitelistPlayers" :key="player">
              <b-td>{{ player }}</b-td>
              <b-td>
                <b-button
                  variant="outline-danger"
                  @click="deleteWhitelistPlayer(player)"
                >
                  <b-icon icon="x"></b-icon>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </fieldset>

      <div class="text-center">
        <b-button type="submit" variant="primary">
          {{ $t("editServer.button") }}
        </b-button>
      </div>
    </b-form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      motd: "",
      version: "1.19.3",
      versions: [
        "1.19.3",
        "1.19.2",
        "1.19.1",
        "1.19",
        "1.18.2",
        "1.18.1",
        "1.18",
        "1.17.1",
        "1.17",
        "1.16.5",
        "1.16.4",
        "1.16.3",
        "1.16.2",
        "1.16.1",
        "1.16",
        "1.15.2",
        "1.15.1",
        "1.15",
        "1.14.4",
        "1.14.3",
        "1.14.2",
        "1.14.1",
        "1.14",
        "1.13.2",
        "1.13.1",
        "1.13",
        "1.12.2",
        "1.12.1",
        "1.12",
        "1.11.2",
        "1.11.1",
        "1.11",
        "1.10.2",
        "1.10.1",
        "1.10",
        "1.9.4",
        "1.9.3",
        "1.9.2",
        "1.9.1",
        "1.9",
        "1.8.9",
        "1.8.8",
        "1.8.7",
        "1.8.6",
        "1.8.5",
        "1.8.4",
        "1.8.3",
        "1.8.2",
        "1.8.1",
        "1.8",
      ],
      maxPlayers: 20,
      whitelist: true,
      gamemode: "Survival",
      gamemodes: ["Survival", "Creative", "Adventure", "Spectator"],
      difficulty: "Easy",
      difficulties: ["Peaceful", "Easy", "Normal", "Hard"],
      hardcore: false,
      fire: false,
      pvp: true,
      monsters: true,
      animals: true,
      npcs: true,
      structures: true,
      whitelistPlayers: [],
      whitelistPlayer: "",
    };
  },
  async mounted() {
    await this.$login();

    const json = {
      access_token: this.$store.state.access_token,
      id: this.$route.params.id,
    };

    this.$http
      .post(`${process.env.VUE_APP_BACKEND_URL}/getServer`, json)
      .then((response) => {
        console.log(response);

        if (response.data.status) {
          for (const propName in response.data) {
            if (propName != "status") {
              this[propName] = response.data[propName];
            }
          }
        } else {
          this.$showModal("error.title", response.data.error, "error");
        }
      })
      .catch((error) => {
        this.$showModal("error.title", "error.unknown", "error");
      });
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      var json = this.$data;
      json.access_token = this.$store.state.access_token;
      json.id = this.$route.params.id;
      json.maxPlayers = parseInt(json.maxPlayers);
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/editServer`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.$router.push({ name: "servers" });
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
    addWhitelistPlayer() {
      this.whitelistPlayer = this.whitelistPlayer.trim();
      if (!this.whitelistPlayer) {
        return;
      }
      if (this.whitelistPlayers.indexOf(this.whitelistPlayer) != -1) {
        this.whitelistPlayer = "";
        this.$showModal("info", "createServer.script.whitelisted", "info");
        return;
      }
      this.whitelistPlayers.push(this.whitelistPlayer);
      this.whitelistPlayer = "";
    },
    deleteWhitelistPlayer(player) {
      this.whitelistPlayers = this.whitelistPlayers.filter(function (
        value,
        index,
        arr
      ) {
        return value != player;
      });
    },
  },
};
</script>

<style scoped>
main {
  width: 75%;
  margin: auto;
}

h4 {
  margin-bottom: 4%;
}

fieldset {
  margin-bottom: 2%;
}

.notVisible {
  color: white;
}

button {
  margin-top: 2%;
  margin-bottom: 5%;
}
</style>