<template>
  <div>
    <b-dropdown variant="light">
      <template #button-content>
        <gb-flag :code="locale.flag" height="18px" iconPath="flags" />
        {{ locale.lang }}
      </template>
      <b-dropdown-item-button
        :value="locale.lang"
        v-for="locale in locales"
        :key="locale.lang"
        @click="setLocale(locale.lang)"
      >
        <gb-flag
          :code="locale.flag || locale.lang"
          size="small"
          iconPath="flags"
        />
        {{ locale.text }}
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locale: {},
      locales: [
        { lang: "en", text: "English", flag: "us" },
        { lang: "de", text: "Deutsch" },
      ],
    };
  },
  beforeMount() {
    this.setLocale();
  },
  methods: {
    setLocale(lang) {
      if (lang) {
        this.$setLocale(lang);
      }

      let sLocale =
        this.locales.find((v) => v.lang == this.$i18n.locale) ||
        this.locales[0];
      this.locale.lang = sLocale.lang.toUpperCase();
      this.locale.flag = sLocale.flag || sLocale.lang;
    },
  },
};
</script>

<style>
.dropdown-toggle::after {
  content: initial !important;
}

.gb-flag--default {
  margin-top: -3px;
}

#__BVID__19__BV_toggle_ {
  margin-top: 1px;
}
</style>