<template>
  <header>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand :to="{ path: '/home' }">Micrortal</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <router-link
            tag="b-nav-item"
            active-class="active"
            to="/home"
            v-if="!$store.state.isLoggedIn"
          >
            {{ $t("head.home") }}
          </router-link>
          <router-link
            tag="b-nav-item"
            active-class="active"
            to="/servers"
            v-if="$store.state.isLoggedIn"
          >
            {{ $t("head.servers") }}
          </router-link>
          <router-link
            tag="b-nav-item"
            active-class="active"
            to="/users"
            v-if="$store.state.isLoggedIn && $store.state.isAdmin"
          >
            {{ $t("head.users") }}
          </router-link>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <LanguageSwitcher />
          <router-link
            tag="b-nav-item"
            active-class="active"
            to="/logout"
            v-if="$store.state.isLoggedIn"
          >
            {{ $t("head.logout") }}
          </router-link>
          <router-link
            tag="b-nav-item"
            active-class="active"
            to="/register"
            v-else
            class="ml-auto"
          >
            {{ $t("head.register") }}
          </router-link>
          <router-link
            tag="b-nav-item"
            active-class="active"
            to="/login"
            v-if="!$store.state.isLoggedIn"
            class="ml-auto"
          >
            {{ $t("head.login") }}
          </router-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  components: {
    LanguageSwitcher,
  },
};
</script>

<style scoped>
header {
  margin-bottom: 3%;
}
</style>