<template>
  <main>
    <h4 class="text-center">{{ $t("head.register") }}</h4>

    <b-form @submit="onSubmit">
      <b-form-group :label="$t('register.username.label')" label-for="username">
        <b-form-input
          v-model="username"
          :placeholder="$t('register.username.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('register.email.label')" label-for="email">
        <b-form-input
          v-model="email"
          type="email"
          :placeholder="$t('register.email.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('register.password.label')" label-for="password">
        <b-form-input
          v-model="password"
          type="password"
          :placeholder="$t('register.password.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox-group required>
          <b-form-checkbox>
            {{ $t("register.check.text") }}
            <router-link to="/privacyPolicy" target="_blank">
              {{ $t("register.check.link") }}
            </router-link>
            {{ $t("register.check.text2") }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <div class="text-center">
        <b-button type="submit" variant="primary">
          {{ $t("head.register") }}
        </b-button>
      </div>
    </b-form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/register`, this.$data)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.$showModal("success", "register.script.send", "success");
            this.$router.push({ name: "login" });
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
  },
};
</script>

<style scoped>
main {
  width: 45%;
  margin: auto;
}

h4 {
  margin-bottom: 6%;
}

button {
  margin-top: 3%;
}
</style>