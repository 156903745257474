<template>
  <main class="text-center">
    <h4>{{ $t("users.title") }}</h4>
    <b-table-simple hover responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th>{{ $t("users.user") }}</b-th>
          <b-th>{{ $t("users.email") }}</b-th>
          <b-th>{{ $t("users.maxServers") }}</b-th>
          <b-th>{{ $t("users.maxRunning") }}</b-th>
          <b-th>{{ $t("users.ban") }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="user in users" :key="user.email">
          <b-td>{{ user.username }}</b-td>
          <b-td>{{ user.email }}</b-td>
          <b-td>
            <b-form-input
              v-model="user.maxServers"
              type="number"
              min="0"
              max="100"
              placeholder="Enter maximal servers"
              @change="maxServersUser(user.email, user.maxServers)"
            ></b-form-input>
          </b-td>
          <b-td>
            <b-form-input
              v-model="user.maxRunning"
              type="number"
              min="0"
              max="100"
              placeholder="Enter maximal running"
              @change="maxRunningUser(user.email, user.maxRunning)"
            ></b-form-input>
          </b-td>
          <b-td>
            <b-form-checkbox
              switch
              @change="banUser(user.email, user.ban)"
              v-model="user.ban"
            ></b-form-checkbox>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </main>
</template>

<script>
export default {
  data() {
    return {
      users: [],
    };
  },
  async mounted() {
    await this.$login();

    if (!this.$store.state.isAdmin) {
      this.$router.push({ name: "servers" });
    } else {
      this.loadUsers();
    }
  },
  methods: {
    loadUsers() {
      const json = { access_token: this.$store.state.access_token };

      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/getUsers`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.users = response.data.users;
          }
        });
    },
    maxServersUser(email, maxServers) {
      const json = {
        access_token: this.$store.state.access_token,
        maxServers: parseInt(maxServers),
        email: email,
      };

      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/maxServersUser`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.loadUsers();
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
    maxRunningUser(email, maxRunning) {
      const json = {
        access_token: this.$store.state.access_token,
        maxRunning: parseInt(maxRunning),
        email: email,
      };

      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/maxRunningUser`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.loadUsers();
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
    banUser(email, ban) {
      const json = {
        access_token: this.$store.state.access_token,
        ban: ban,
        email: email,
      };

      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/banUser`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.loadUsers();
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
  },
};
</script>

<style scoped>
h4 {
  margin-bottom: 2%;
}
</style>