import Vue from 'vue';
import Vuex from 'vuex';
import cookie from 'vue-cookies'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modal: {
      show: false,
      title: "",
      text: "",
      type: "",
    },
    access_token: cookie.get("access_token") || "",
    isLoggedIn: false,
    isAdmin: false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
