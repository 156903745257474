<template>
  <main>
    <img src="@/assets/Logo.jpg" class="rounded" />

    <div id="info" class="rounded">
      <b-container class="bv-example-row text-center">
        <b-row>
          <b-col class="outside">
            <b-icon icon="plus-circle" class="outsideIcon"></b-icon>
            <h2>{{ $t("home.create.title") }}</h2>
            <p>{{ $t("home.create.text") }}</p>
            <router-link to="/register">
              <h5>{{ $t("home.create.link") }}</h5>
            </router-link>
          </b-col>
          <b-col class="middle">
            <b-icon icon="controller" id="middleIcon"></b-icon>
            <h2>{{ $t("home.play.title") }}</h2>
            <p>{{ $t("home.play.text") }}</p>
            <router-link to="/register">
              <h5>{{ $t("home.play.link") }}</h5>
            </router-link>
          </b-col>
          <b-col class="outside">
            <b-icon icon="tools" class="outsideIcon"></b-icon>
            <h2>{{ $t("home.control.title") }}</h2>
            <p>{{ $t("home.control.text") }}</p>
            <router-link to="/register">
              <h5>{{ $t("home.control.link") }}</h5>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </main>
</template>

<script>
export default {
  beforeCreate() {
    this.$login();
  },
};
</script>

<style scoped>
main {
  width: 78%;
  margin: auto;
}

img {
  width: 100%;
}

#info {
  margin-top: 5%;
  background-color: #effbff;
}

.outside {
  padding-top: 7%;
}

svg {
  font-size: 287%;
  margin-bottom: 7%;
}

#middleIcon {
  font-size: 475%;
  margin-top: 5%;
  margin-bottom: 4%;
  color: rgb(35, 171, 255);
}

.outsideIcon {
  margin-top: 5%;
  color: rgb(91, 192, 255);
}

p {
  padding: 7%;
}

h5 {
  padding-top: 3.5%;
  padding-bottom: 10%;
}
</style>
