<template>
  <main>
    <h4 class="text-center">{{ $t("head.login") }}</h4>

    <b-form @submit="onSubmit">
      <b-form-group :label="$t('login.username.label')" label-for="username">
        <b-form-input
          v-model="username"
          :placeholder="$t('login.username.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('login.password.label')" label-for="password">
        <b-form-input
          v-model="password"
          type="password"
          :placeholder="$t('login.password.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <router-link to="/recovery">{{ $t("login.link") }}</router-link>
      <br />

      <div class="text-center">
        <b-button type="submit" variant="primary">
          {{ $t("head.login") }}
        </b-button>
      </div>
    </b-form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  beforeCreate() {
    if (this.$route.query.email && this.$route.query.activate_token) {
      const json = {
        email: this.$route.query.email,
        activate_token: this.$route.query.activate_token,
      };
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/activate`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.$showModal(
              "login.script.activated.title",
              "login.script.activated.text",
              "success"
            );
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    } else {
      this.$login();
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/login`, this.$data)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.$cookies.set(
              "access_token",
              response.data.access_token,
              "31d"
            );
            this.$store.state.access_token = response.data.access_token;
            this.$store.state.isLoggedIn = true;
            if (response.data.admin) {
              this.$store.state.isAdmin = true;
            }

            this.$router.push({ path: this.$route.query.to || "/servers" });
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
  },
};
</script>

<style scoped>
main {
  width: 45%;
  margin: auto;
}

h4 {
  margin-bottom: 6%;
}

button {
  margin-top: 5%;
}
</style>