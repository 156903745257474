import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueFlags from "@growthbunker/vueflags"
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import axios from 'axios';
import store from './store';
import App from './App.vue';
import router from './router';
import VueCookies from 'vue-cookies';
import en from "@/locales/en";
import de from "@/locales/de";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueI18n);
Vue.use(VueFlags);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCookies);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

Vue.prototype.$showModal = function (title, text, type) {
  this.$store.state.modal.title = this.$i18n.t(title);
  this.$store.state.modal.text = this.$i18n.t(text);
  this.$store.state.modal.type = type;
  this.$store.state.modal.show = true;
};

Vue.prototype.$login = async function () {
  if (!this.$store.state.isLoggedIn) {
    if (this.$store.state.access_token) {
      const json = { access_token: this.$store.state.access_token };
      let response = await this.$http.post(`${process.env.VUE_APP_BACKEND_URL}/login`, json);
      console.log(response);

      if (response.data.status) {
        this.$cookies.set(
          "access_token",
          response.data.access_token,
          "31d"
        );
        this.$store.state.access_token = response.data.access_token;
        this.$store.state.isLoggedIn = true;
        if (response.data.admin) {
          this.$store.state.isAdmin = true;
        }
      }
    } else if (this.$route.path != "/login" && this.$route.path != "/home") {
      this.$router.push({ path: "/login?to=" + this.$route.path });
    }
  }
};

Vue.prototype.$setLocale = function (locale) {
  i18n.locale = locale;
}

function getBrowserLocale() {
  let locale = navigator.languages ? navigator.languages[0] : navigator.language;
  return locale.trim().split(/-|_/)[0];
}

const i18n = new VueI18n({
  locale: getBrowserLocale(),
  fallbackLocale: "en",
  messages: {
    en: en,
    de: de
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n
}).$mount('#app');
