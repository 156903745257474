<template>
  <b-modal
    :title="$store.state.modal.title"
    @shown="onShow"
    @hidden="onClose"
    v-model="$store.state.modal.show"
    :header-bg-variant="variant"
    ok-only
  >
    <p>{{ $store.state.modal.text }}</p>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      variant: "",
      variants: { success: "success", error: "danger", info: "info" },
    };
  },
  methods: {
    onShow() {
      this.variant = this.variants[this.$store.state.modal.type] || "info";
    },
    onClose() {
      this.$store.state.modal.show = false;
      this.$store.state.modal.title = "";
      this.$store.state.modal.text = "";
      this.$store.state.modal.type = "";
      this.variant = "";
    },
  },
};
</script>