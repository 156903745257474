<template>
  <main>
    <h4 class="text-center">{{ $t("recovery.title") }}</h4>

    <b-form @submit="onSubmit">
      <b-form-group
        :label="$t('recovery.password.label')"
        label-for="password"
        v-if="$route.query.email && $route.query.recovery_token"
      >
        <b-form-input
          v-model="password"
          type="password"
          :placeholder="$t('recovery.password.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('recovery.username.label')"
        label-for="username"
        v-else
      >
        <b-form-input
          v-model="username"
          :placeholder="$t('recovery.username.placeholder')"
          required
          trim
        ></b-form-input>
      </b-form-group>

      <div class="text-center">
        <b-button type="submit" variant="primary">
          {{ $t("recovery.button") }}
        </b-button>
      </div>
    </b-form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      if (this.$route.query.email && this.$route.query.recovery_token) {
        const json = {
          email: this.$route.query.email,
          recovery_token: this.$route.query.recovery_token,
          password: this.$data.password,
        };
        this.$http
          .post(`${process.env.VUE_APP_BACKEND_URL}/recovery`, json)
          .then((response) => {
            console.log(response);

            if (response.data.status) {
              this.$showModal("success", "recovery.script.reseted", "success");
              this.$router.push({ name: "login" });
            } else {
              this.$showModal("error.title", response.data.error, "error");
            }
          })
          .catch((error) => {
            this.$showModal("error.title", "error.unknown", "error");
          });
      } else {
        const json = { username: this.$data.username };
        this.$http
          .post(`${process.env.VUE_APP_BACKEND_URL}/getRecovery`, json)
          .then((response) => {
            console.log(response);

            if (response.data.status) {
              this.$showModal("success", "recovery.script.send", "success");
              this.$router.push({ name: "login" });
            } else {
              this.$showModal("error.title", response.data.error, "error");
            }
          })
          .catch((error) => {
            this.$showModal("error.title", "error.unknown", "error");
          });
      }
    },
  },
};
</script>

<style scoped>
main {
  width: 45%;
  margin: auto;
}

h4 {
  margin-bottom: 6%;
}

button {
  margin-top: 5%;
}
</style>
