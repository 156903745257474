<template>
  <main class="text-center">
    <h4>{{ $t("backups.title") }}</h4>

    <b-table-simple hover responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th>
            {{ $t("backups.backup") }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="backup in backups" :key="backup.link">
          <b-td>
            <a :href="backup.link" target="_blank">{{ backup.date }}</a>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </main>
</template>

<script>
export default {
  data() {
    return {
      backups: [],
    };
  },
  async mounted() {
    await this.$login();

    const json = {
      access_token: this.$store.state.access_token,
      id: this.$route.params.id,
    };

    this.$http
      .post(`${process.env.VUE_APP_BACKEND_URL}/getBackups`, json)
      .then((response) => {
        console.log(response);

        if (response.data.status) {
          this.backups = response.data.backups;
        } else {
          this.$showModal("error.title", response.data.error, "error");
        }
      })
      .catch((error) => {
        this.$showModal("error.title", "error.unknown", "error");
      });
  },
};
</script>

<style scoped>
main {
  margin: auto;
}

h4 {
  margin-bottom: 4%;
}
</style>