<template>
  <main class="text-center">
    <h4>{{ $t("servers.title") }}</h4>
    <b-table-simple hover responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            v-if="$store.state.isAdmin || list.find((x) => x.admin == false)"
          >
            {{ $t("servers.user") }}
          </b-th>
          <b-th>{{ $t("servers.label") }}</b-th>
          <b-th>{{ $t("servers.address") }}</b-th>
          <b-th>{{ $t("servers.start") }}</b-th>
          <b-th>{{ $t("servers.stop") }}</b-th>
          <b-th>{{ $t("servers.edit") }}</b-th>
          <b-th>{{ $t("servers.mods") }}</b-th>
          <b-th>{{ $t("servers.backups") }}</b-th>
          <b-th>{{ $t("servers.delete") }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="server in list" :key="server.id">
          <b-td
            v-if="$store.state.isAdmin || list.find((x) => x.admin == false)"
            >{{ server.username }}</b-td
          >
          <b-td>{{ server.name }}</b-td>
          <b-td>{{ server.address }}</b-td>
          <b-td
            ><b-button
              :disabled="
                server.running ||
                (running >= maxRunning && !$store.state.isAdmin && server.admin)
              "
              variant="outline-success"
              @click="startServer(server.id)"
              ><b-icon icon="play-fill"></b-icon></b-button
          ></b-td>
          <b-td
            ><b-button
              :disabled="!server.running"
              variant="outline-danger"
              @click="stopServer(server.id)"
              ><b-icon icon="stop-fill"></b-icon></b-button
          ></b-td>
          <b-td
            ><b-button
              :disabled="!server.admin && !$store.state.isAdmin"
              variant="outline-info"
              @click="editServer(server.id)"
              ><b-icon icon="pencil-fill"></b-icon></b-button
          ></b-td>
          <b-td
            ><b-button
              :disabled="!server.admin && !$store.state.isAdmin"
              variant="outline-info"
              @click="moderateServer(server.id)"
              ><b-icon icon="people-fill"></b-icon></b-button
          ></b-td>
          <b-td
            ><b-button
              :disabled="!server.admin && !$store.state.isAdmin"
              variant="outline-info"
              @click="backupServer(server.id)"
              ><b-icon icon="collection-fill"></b-icon></b-button
          ></b-td>
          <b-td
            ><b-button
              :disabled="
                server.running || (!server.admin && !$store.state.isAdmin)
              "
              variant="outline-danger"
              @click="deleteServer(server.id)"
              ><b-icon icon="x"></b-icon></b-button
          ></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <br />
    <br />
    <div v-if="servers < maxServers || $store.state.isAdmin">
      <h4>{{ $t("servers.new") }}</h4>
      <b-button variant="primary" @click="createServer"
        ><b-icon icon="plus"></b-icon
      ></b-button>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      maxRunning: 0,
      running: 0,
      maxServers: 0,
      servers: 0,
      delete: "",
    };
  },
  async mounted() {
    await this.$login();
    this.loadServers();
  },
  methods: {
    loadServers() {
      const json = { access_token: this.$store.state.access_token };
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/getServers`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.list = response.data.list;
            this.maxRunning = response.data.maxRunning;
            this.running = response.data.running;
            this.maxServers = response.data.maxServers;
            this.servers = response.data.servers;
          }
        });
    },
    startServer(id) {
      const json = { access_token: this.$store.state.access_token, id: id };
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/startServer`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.loadServers();
            this.$showModal(
              "servers.script.start.title",
              "servers.script.start.text",
              "success"
            );
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
    stopServer(id) {
      const json = { access_token: this.$store.state.access_token, id: id };
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/stopServer`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.loadServers();
            this.$showModal(
              "servers.script.stop.title",
              "servers.script.stop.text",
              "success"
            );
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
    editServer(id) {
      this.$router.push({ path: `/server/${id}/edit` });
    },
    moderateServer(id) {
      this.$router.push({ path: `/server/${id}/moderate` });
    },
    backupServer(id) {
      this.$router.push({ path: `/server/${id}/backups` });
    },
    deleteServer(id) {
      if (this.delete != id) {
        this.delete = id;
        this.$showModal(
          "servers.script.delete.title",
          "servers.script.delete.text",
          "info"
        );
      } else {
        const json = { access_token: this.$store.state.access_token, id: id };
        this.$http
          .post(`${process.env.VUE_APP_BACKEND_URL}/deleteServer`, json)
          .then((response) => {
            console.log(response);

            if (response.data.status) {
              this.loadServers();
              this.$showModal(
                "servers.script.deleted.title",
                "servers.script.deleted.text",
                "success"
              );
            } else {
              this.$showModal("error.title", response.data.error, "error");
            }
          })
          .catch((error) => {
            this.$showModal("error.title", "error.unknown", "error");
          });
      }
    },
    createServer() {
      this.$router.push({ name: "createServer" });
    },
  },
};
</script>

<style scoped>
h4 {
  margin-bottom: 2%;
}
</style>