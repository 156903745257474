import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Register from "@/views/Register.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import NotFound from "@/views/NotFound.vue";
import Servers from "@/views/Servers.vue";
import CreateServer from "@/views/CreateServer.vue";
import Server from "@/views/Server.vue";
import EditServer from "@/views/Server/EditServer.vue";
import ModerateServer from "@/views/Server/ModerateServer.vue";
import BackupServer from "@/views/Server/BackupServer.vue";
import Users from "@/views/Users.vue";
import Recovery from "@/views/Recovery.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/recovery",
    name: "recovery",
    component: Recovery,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/servers",
    name: "servers",
    component: Servers,
  },
  {
    path: "/createServer",
    name: "createServer",
    component: CreateServer,
  },
  {
    path: "/server/:id",
    name: "server",
    component: Server,
    children: [
      {
        path: "edit",
        component: EditServer
      },
      {
        path: "moderate",
        component: ModerateServer
      },
      {
        path: "backups",
        component: BackupServer
      }
    ],
  },
  {
    path: "/users",
    name: "users",
    component: Users,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
