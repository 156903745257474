<template>
  <main>
    <h4 class="text-center" v-if="!$store.state.isLoggedIn">
      {{ $t("logout.label") }}
      <br />
      <router-link to="/home">
        {{ $t("logout.link") }}
      </router-link>
    </h4>
  </main>
</template>

<script>
export default {
  mounted() {
    const json = { access_token: this.$store.state.access_token };

    this.$http
      .post(`${process.env.VUE_APP_BACKEND_URL}/logout`, json)
      .then((response) => {
        console.log(response);

        if (response.data.status) {
          this.$cookies.remove("access_token");
          this.$store.state.access_token = response.data.access_token;
          this.$store.state.isLoggedIn = false;
        }
      })
      .catch((error) => {
        this.$showModal("error.title", "error.unknown", "error");
      });
  },
};
</script>

<style scoped>
main {
  width: 45%;
  margin: auto;
}

h4 {
  margin-bottom: 6%;
}

button {
  margin-top: 5%;
}
</style>