<template>
  <main>
    <h4 class="text-center">{{ $t("moderateServer.title") }}</h4>
    <b-form @submit="onSubmit">
      <b-form-group :label="$t('moderateServer.label')" label-for="moderator">
        <b-input-group class="mt-3">
          <b-form-input
            v-model="moderator"
            :placeholder="$t('moderateServer.placeholder')"
            trim
            @enter="addModerator"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="addModerator">
              {{ $t("moderateServer.add") }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-table-simple hover responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>
              {{ $t("moderateServer.moderator") }}
            </b-th>
            <b-th>
              {{ $t("moderateServer.delete") }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="moderator in moderators" :key="moderator">
            <b-td>{{ moderator }}</b-td>
            <b-td
              ><b-button
                variant="outline-danger"
                @click="deleteModerator(moderator)"
                ><b-icon icon="x"></b-icon></b-button
            ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="text-center">
        <b-button type="submit" variant="primary">
          {{ $t("moderateServer.button") }}
        </b-button>
      </div>
    </b-form>
  </main>
</template>

<script>
export default {
  data() {
    return {
      moderators: [],
      moderator: "",
    };
  },
  async mounted() {
    await this.$login();

    const json = {
      access_token: this.$store.state.access_token,
      id: this.$route.params.id,
    };

    this.$http
      .post(`${process.env.VUE_APP_BACKEND_URL}/getModerators`, json)
      .then((response) => {
        console.log(response);

        if (response.data.status) {
          this.moderators = response.data.moderators;
        } else {
          this.$showModal("error.title", response.data.error, "error");
        }
      })
      .catch((error) => {
        this.$showModal("error.title", "error.unknown", "error");
      });
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      var json = this.$data;
      json.access_token = this.$store.state.access_token;
      json.id = this.$route.params.id;
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_URL}/setModerators`, json)
        .then((response) => {
          console.log(response);

          if (response.data.status) {
            this.$router.push({ name: "servers" });
          } else {
            this.$showModal("error.title", response.data.error, "error");
          }
        })
        .catch((error) => {
          this.$showModal("error.title", "error.unknown", "error");
        });
    },
    addModerator() {
      this.moderator = this.moderator.trim();
      if (!this.moderator) {
        return;
      }
      if (this.moderators.includes(this.moderator)) {
        this.moderator = "";
        this.$showModal("info", "moderateServer.script.moderator", "info");
        return;
      }
      this.moderators.push(this.moderator);
      this.moderator = "";
    },
    deleteModerator(moderator) {
      this.moderators = this.moderators.filter(function (value, index, arr) {
        return value != moderator;
      });
    },
  },
};
</script>

<style scoped>
main {
  width: 75%;
  margin: auto;
}

h4 {
  margin-bottom: 4%;
}

button {
  margin-top: 2%;
  margin-bottom: 5%;
}
</style>